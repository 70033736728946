<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="图片" width='150px' prop="unitQualificationFileUrl">
          <template slot-scope="scope">
            <my-image 
              style="height: 100px"
              :src="scope.row.unitQualificationFileUrl" 
              :preview-src-list="[scope.row.unitQualificationFileUrl]">
            </my-image>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <qualify-edit ref="editForm" @getData="getData"></qualify-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import qualifyEdit from './qualifyEdit.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'qualifyList',
    components: {
      pagination,
      breadcrumb,
      qualifyEdit
    },
    data() {
      let title = '资质信息'
      return {
        allSelData: [],
        breadStaticData:['主体信息', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId()
        },
        urlName: 'psfindqualification',
        tableHead: [{
          prop: 'unitQualificationFileName',
          label: '证书名称',
          minWidth: 150
        },{
          prop: 'unitQualificationTypeName',
          label: '资质类型',
          width: 180
        },{
          prop: 'unitQualificationClassName',
          label: '资质类别',
          width: 180
        },{
          prop: 'issueUnit',
          label: '颁发单位'
        },{
          prop: 'issueTime',
          label: '颁发日期',
          width: 150
        },{
          prop: 'abortTime',
          label: '截止日期',
          width: 150
        }],
        initForm: {
          unitQualificationFileId: '',
          unitQualificationFileUrl: [],
          unitQualificationFileDirectory: '',
          unitQualificationType: '',
          unitQualificationClass: '',
          unitQualificationFileName: '',
          issueUnit: '',
          issueTime: '',
          abortTime: '',
          remark: '',
        },
        loadtext: '加载中',
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      async initTable(){
        await this.getDictCode('101')
        this.getData()
      },
      async getDictCode(dictCode){
        let _this = this
        return new Promise((resolve, reject) => {

          request.commondict({
            dictCode: dictCode
          }).then(res => {
            if(res.code == 200){
              _this.allSelData = res.data[0].childrenList
            } else {
              _this.message(res.msg || '', "error")
            }
            resolve(true)
          }).catch(e => {
            resolve(false)
            console.log(e)
          })
        })
      },
      getFirst(dictCode, childDatas){
        let datas = childDatas || this.allSelData
        datas.forEach(cur => {
          if(cur.dictCode == dictCode){
            dictCode = cur.dictName
          }
        }) 
        return dictCode
      },
      getSec(dictCode1, dictCode2){
        let datas = this.allSelData
        datas.forEach(cur => {
          if(cur.dictCode == dictCode1 && cur.childrenList?.length){
            dictCode2 = this.getFirst(dictCode2, cur.childrenList)
          }
        }) 
        return dictCode2
      },
      async add(){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          certificateOfComplianceId: id
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', obj)
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.apfindqualification({
          unitQualificationFileId: data.unitQualificationFileId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              unitQualificationFileUrl: result.unitQualificationFileUrl ? result.unitQualificationFileUrl.split(',') : [],
              issueTime: result.issueTime ? utils.dateFormat(result.issueTime, 'yyyy-MM-dd') : '',
              abortTime: result.abortTime ? utils.dateFormat(result.abortTime, 'yyyy-MM-dd') : ''
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.unitQualificationFileName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.deletequalification({
            unitQualificationFileId: data.unitQualificationFileId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                unitQualificationFileUrl: item.unitQualificationFileUrl ? item.unitQualificationFileUrl.split(',')[0] : '',
                unitQualificationTypeName: _this.getFirst(item.unitQualificationType),
                unitQualificationClassName: _this.getSec(item.unitQualificationType, item.unitQualificationClass)
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>