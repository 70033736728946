<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="800px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="100px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="资质类型：" prop="unitQualificationType">
                <el-select v-model="ruleForm.unitQualificationType" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择资质类型'" @change="selecChange" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.type"
                    :key="index"
                    :label="item.dictName"
                    :value="item.dictCode">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" v-if="selectObj.class.length">
              <el-form-item label="资质类别：" prop="unitQualificationClass">
                <el-select v-model="ruleForm.unitQualificationClass" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择资质类别'" @change="selecChange2" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.class"
                    :key="index"
                    :label="item.dictName"
                    :value="item.dictCode">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="证书名称：" prop="unitQualificationFileName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.unitQualificationFileName }}</p>
                <el-input v-else clearable v-model="ruleForm.unitQualificationFileName" placeholder="请输入证书名称" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="颁发单位：" prop="issueUnit">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.issueUnit }}</p>
                <el-input v-else clearable v-model="ruleForm.issueUnit" placeholder="请输入颁发单位" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="颁发时间：" prop="issueTime">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.issueTime }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.issueTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择颁发时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="截止时间：" prop="abortTime">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.abortTime }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.abortTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择截止时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.remark }}</p>
                <el-input clearable v-else v-model="ruleForm.remark" placeholder="请输入备注" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="上传附件：" prop="unitQualificationFileUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.unitQualificationFileUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.unitQualificationFileUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.unitQualificationFileUrl.length" class="no-data">无图片</div>
                <!-- <div class="all-video" v-for="(item, index) in ruleForm.unitQualificationFileUrl" :key="index">
                  <video :src="item" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                </div>
                <div v-if="editType == 'look' && !ruleForm.unitQualificationFileUrl.length" class="no-data">无视频</div> -->
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="105001" :businessCode="ruleForm.unitQualificationFileId" :imgSize="0.5" propName="unitQualificationFileUrl" storeTableName="unitQualificationFileDirectory" @getpics="getpics"></pics>
    <!-- <vids ref="pics" dictCode="410012" :businessCode="ruleForm.unitQualificationFileId" propName="unitQualificationFileUrl" storeTableName="unitQualificationFileDirectory" @getpics="getpics"></vids> -->
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import pics from './../common/pics.vue'
import vids from './../common/vids.vue'
export default {
  name: "qualifyEdit",
  components: {
    vids,
    pics
  },
  props: {},
  data() {
    return {   
      rules: {
        unitQualificationType: [{ required: true,  trigger: "change", message: '请选择资质类型'}],
        unitQualificationFileName: [{ required: true,  trigger: "change", message: '请输入证书名称'}],
        unitQualificationFileUrl: [{ required: true,  trigger: "change", message: '请输入证书名称'}],
        issueTime: [{ required: true,  trigger: "change", message: '请选择颁发时间'}],
        abortTime: [{ required: true,  trigger: "change", message: '请选择截止时间'}]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      selectObj: {
        type: [],
        class: []
      }
    };
  },
  created() {
    this.getDictCode('101')
	},
  methods: {
    async getDictCode(dictCode){
      let _this = this
      return new Promise((resolve, reject) => {
        request.commondict({
          dictCode: dictCode
        }).then(res => {
          if(res.code == 200){
            _this.selectObj.type = res.data[0].childrenList
          } else {
            _this.message(res.msg || '', "error")
          }
          resolve(true)
        }).catch(e => {
          resolve(false)
          console.log(e)
        })
      })
    },
    selecChange(val){
      let datas = this.selectObj.type,
        childrenList = []
      datas.forEach(cur => {
        if(cur.dictCode == val && cur.childrenList?.length){
          childrenList = cur.childrenList
        }
      })
      this.selectObj.class = childrenList
      this.ruleForm.unitQualificationClass = ''
      this.ruleForm.unitQualificationFileName = this.getSelLabel('type', val)

    },
    selecChange2(val){
      this.ruleForm.unitQualificationFileName = this.getSelLabel('class', val)
    },
    getSelLabel(prop, id, idProp = 'dictCode', selprop = 'dictName'){
      let datas = this.selectObj[prop],
        label = ''
      datas.forEach(cur => {
        if(cur[idProp] == id){
          label = cur[selprop]
        }
      })
      return label
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.unitQualificationFileUrl)
    },
    getpics(data){
      console.log(data)
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = ruleForm
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
      this.selectObj.class = []
    },
    initEdit(){
      let ruleForm = this.ruleForm,
        datas = this.selectObj.type,
        childrenList = []
      datas.forEach(cur => {
        if(cur.dictCode == ruleForm.unitQualificationType && cur.childrenList?.length){
          childrenList = cur.childrenList
        }
      })
      this.selectObj.class = childrenList
    },
    initLook(){
      this.initEdit()
    },
    submitForm(formName) {
      let _this = this,
        editType = this.editType;
      let mesInfo = {
        add: "新增成功！",
        edit: "修改成功！"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'savequalificationcertificate' : 'updatequalification',
            target = {
            ..._this.ruleForm,
            unitId: utils.getBodyId(),
            unitQualificationFileUrl: _this.ruleForm.unitQualificationFileUrl?.join(',') || '',
            issueTime: utils.dateFormat(_this.ruleForm.issueTime),
            abortTime: utils.dateFormat(_this.ruleForm.abortTime)
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              _this.closeModel()
              _this.$emit("getData", editType == 'add' ? true : false)
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>


